<template>
    <form class="app-search d-none d-md-block">
        <div class="position-relative">
            <input
            type="text"
            class="form-control"
            placeholder="Search..."
            autocomplete="off"
            id="search-options"
            value=""
            />
            <span class="mdi mdi-magnify search-widget-icon"></span>
            <span
            class="
                mdi mdi-close-circle
                search-widget-icon search-widget-icon-close
                d-none
            "
            id="search-close-options"
            ></span>
        </div>
        <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
            <SimpleBar data-simplebar style="max-height: 320px">
            <!-- item-->
            <div class="dropdown-header">
                <h6 class="text-overflow text-muted mb-0 text-uppercase">
                Recent Searches
                </h6>
            </div>

            <div class="dropdown-item bg-transparent text-wrap">
                <router-link
                to="/activity"
                class="btn me-2 btn-soft-secondary btn-sm btn-rounded"
                >Activity <i class="mdi mdi-magnify ms-1"></i
                ></router-link>
                <router-link
                to="/"
                class="btn btn-soft-secondary btn-sm btn-rounded"
                >Board <i class="mdi mdi-magnify ms-1"></i
                ></router-link>
            </div>
            <!-- item-->
            <div class="dropdown-header mt-2">
                <h6 class="text-overflow text-muted mb-1 text-uppercase">
                Pages
                </h6>
            </div>

            <!-- item-->
            <router-link to="/orders" class="dropdown-item notify-item">
                <i
                class="
                    ri-bubble-chart-line
                    align-middle
                    fs-18
                    text-muted
                    me-2
                "
                ></i>
                <span>My orders</span>
            </router-link>

            <!-- item-->
            <router-link to="/settings/notification" class="dropdown-item notify-item">
                <i
                class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"
                ></i>
                <span>Notification</span>
            </router-link>

            <!-- item-->
            <router-link to="/settings/profile" class="dropdown-item notify-item">
                <i
                class="
                    ri-user-settings-line
                    align-middle
                    fs-18
                    text-muted
                    me-2
                "
                ></i>
                <span>Account settings</span>
            </router-link>

            </SimpleBar>
        </div>
    </form>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";

export default {
    components: { SimpleBar },
    mounted(){
        this.isCustomDropdown();
    },
    methods:{
        isCustomDropdown() {
            //Search bar
            var searchOptions = document.getElementById("search-close-options");
            var dropdown = document.getElementById("search-dropdown");
            var searchInput = document.getElementById("search-options");

            if(searchInput){
                searchInput.addEventListener("focus", () => {
                    var inputLength = searchInput.value.length;
                    if (inputLength > 0) {
                        dropdown.classList.add("show");
                        searchOptions.classList.remove("d-none");
                    } else {
                        dropdown.classList.remove("show");
                        searchOptions.classList.add("d-none");
                    }
                });

                searchInput.addEventListener("keyup", () => {
                    var inputLength = searchInput.value.length;
                    if (inputLength > 0) {
                        dropdown.classList.add("show");
                        searchOptions.classList.remove("d-none");
                    } else {
                        dropdown.classList.remove("show");
                        searchOptions.classList.add("d-none");
                    }
                });
            }

            if(searchOptions){
                searchOptions.addEventListener("click", () => {
                    searchInput.value = "";
                    dropdown.classList.remove("show");
                    searchOptions.classList.add("d-none");
                });
            }

            document.body.addEventListener("click", (e) => {
                if (e.target.getAttribute("id") !== "search-options") {
                    dropdown.classList.remove("show");
                    searchOptions.classList.add("d-none");
                }
            });
        },
    }
}
</script>